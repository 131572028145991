<template>
  <div
    v-if="!isLoading"
    class="d-flex flex-column align-items-center"
  >
    <b-card style="width:70%">
      <div class="text-center mb-3">
        <h2 class="text-primary">
          DELIVERY SUMMARY
        </h2>
      </div>
      <app-timeline>
        <YourInformation
          :order-data="order"
        />
        <DeliveryOverview
          :delivery-id="order.reference_id"
          :user-id="order.user.id"
          :status="order.status"
          :total-spent="order.price"
        />
        <DateTimelineItem
          :order-data="order"
        />
        <AddressesTimelineItem
          :pick-up-address="order.sender ? order.sender.complete : ''"
          :drop-off-address="order.recipient ? order.recipient.complete : ''"
        />
        <ProductTimelineItem
          :category="order.category.text"
          :items="items"
        />
        <DownloadableTemplate
          :title="'DELIVERY ORDER'"
          :btn-text="'Download Here'"
          :downloaded-file="fileDownloaded"
          :type-of-file="'do'"
          @downloadFile="handleDownloadFile"
        />
        <DownloadableTemplate
          :title="'COVER NOTE'"
          :btn-text="'Download Here'"
          :downloaded-file="fileDownloaded"
          :type-of-file="'cover'"
          @downloadFile="handleDownloadFile"
        />
        <DownloadableTemplate
          :title="'GOOD RETURN NOTES'"
          :btn-text="'Download Here'"
          :downloaded-file="fileDownloaded"
          :type-of-file="'grn'"
          @downloadFile="handleDownloadFile"
        />
        <DownloadInvoice :invoice-link="order.invoice.receipt" />
        <app-timeline-item icon="UploadIcon">
          <h4>PROOF OF DELIVERY</h4>
          <ProofOfDelivery
            :drop-off-photo="order.delivered_photo"
            :pickup-photo="order.pickup_photo"
            :good-return-photo="order.good_return_photo"
          />
        </app-timeline-item>
      </app-timeline>
      <b-container class="text-center">
        <b-button
          type="button"
          variant="primary"
          class="mt-2"
          to="/voucher-logs"
        >Back</b-button>
      </b-container>
    </b-card>
  </div>
</template>

<script>
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import {
  AddressesTimelineItem,
  DateTimelineItem,
  DeliveryOverview,
  ProductTimelineItem,
  YourInformation,
  DownloadInvoice,
  ProofOfDelivery,
  DownloadableTemplate,
} from '@/components/DeliverySummary'
import axios from '@axios'

export default {
  components: {
    AddressesTimelineItem,
    DateTimelineItem,
    DeliveryOverview,
    ProductTimelineItem,
    YourInformation,
    DownloadInvoice,
    DownloadableTemplate,
    ProofOfDelivery,
    AppTimeline,
    AppTimelineItem,
  },
  data() {
    return {
      fileDownloaded: '',
      order: null,
      isLoading: false,
    }
  },
  computed: {
    items() {
      return [
        { label: 'Quantity', value: this.order.quantity },
        { label: 'Type of Packaging', value: this.order.packaging.text },
        { label: `Size(${this.order.unit})`, value: this.order.size },
        { label: 'SKU', value: this.order.sku },
        { label: 'Expiry Date', value: this.order.po_expiry_date },
        { label: 'Remarks', value: this.order.remarks },
      ]
    },
  },
  created() {
    this.fetchOrder()
  },
  methods: {
    async handleDownloadFile(type) {
      this.fileDownloaded = type
      const response = await this.$http.get(`/b2b/${type}/id/${this.$route.params.id}`, { responseType: 'blob' })
      const fileURL = window.URL.createObjectURL(new Blob([response.data]))
      const fileLink = document.createElement('a')

      fileLink.href = fileURL
      fileLink.setAttribute('download', `${type}-${this.$route.params.id}.pdf`)
      document.body.appendChild(fileLink)
      fileLink.click()
      this.fileDownloaded = ''
    },
    async fetchOrder() {
      try {
        this.isLoading = true
        const response = await axios.get(`/orders/${this.$route.params.id}`)
        if (response.status === 200) {
          this.order = response.data.data
        }
        this.isLoading = false
      } catch (error) {
        throw new Error('Something went wrong.', error)
      }
    },
  },
}
</script>
